import React, { useEffect, useState, useMemo } from 'react';
import { useAuthValue } from '../utils/AuthContext';
import { useHistory } from "react-router-dom";
import { collection, query, where, getDocs, Timestamp, orderBy, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";
import UserWaiting from '../components/HospitalView/UserWaiting';

const Hospital = () => {
  const [hospitalData, setHospitalData] = useState([]);

  const {currentUser} = useAuthValue();
  const history = useHistory();

  if (currentUser === null) {
    //redirect to home to
    history.push("/")
  }

  useEffect(() => {
    const q = query(collection(db, "Hospital"), where("hopitalID", "==", currentUser.uid))
    const getData =  () => {
      getDocs(q).then((querySnapshot) => {
        const hospital = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        setHospitalData(hospital);
      })
    }
    getData();
  }, [])



  return (
    <div className="Hospital">
      <div className="tableaux">
        <div className="waiting">
          <h4>En attente</h4>
          {hospitalData.map((info, index) => (
            <UserWaiting doctors={info} key={index}/>
          ))}
        </div>
        <div className="accepted">
        <h4>Acceptés</h4>

        </div>
      </div>
    </div>
  );
};

export default Hospital;
