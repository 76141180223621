import React from 'react';
import Nav from '../components/Nav';
import SignInHistory from '../components/SignInHistory';
import Footer from '../components/Footer';
import SignInHospital from '../components/SignInHospital';

const Home = () => {

  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        style={{ color: "#0071bc", fontFamily: "Gotham SSm A", padding: 16 }}
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}>
        {children}
      </a>
    );
  };
  return (
    <div className="Home">
       <div>
        <Mailto
          email="elio.disegni@e-cono.fr,jeremie.serval@e-cono.fr"
          subject="Demande d'inscription"
          body="">
          Rejoindre l'aventure E-cono !
        </Mailto>
      </div>
      <div className="signin">
      <SignInHistory />
      <SignInHospital />
      </div>
      <>
      <Footer />
      </>
    </div>
  );
};

export default Home;
