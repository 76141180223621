import React, { useState } from "react";

import { useHistory } from "react-router-dom";
import { auth, db } from "../utils/firebase";
import {
  signInWithEmailAndPassword,
} from "firebase/auth";


const SignInHistory = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  
  const login = e => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password).then(() => {
      history.push("/history");
    });
  };
  
  return (
    <div className="SignInHistory">
      <form onSubmit={login}>
        <h3>Mon historique</h3>
        <div className="formEmail">
          <input
            type="text"
            value={email}
            required
            placeholder="Enter your email"
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <div className="formPassword">
          <input
            type="password"
            value={password}
            required
            placeholder="Enter your password"
            onChange={e => setPassword(e.target.value)}

          />
        </div>
        <div className="button">
          <button>Connexion</button>
        </div>
      </form>
    </div>
  );
};

export default SignInHistory;
