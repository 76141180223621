import React, { useState, useEffect } from 'react';
import { useAuthValue } from '../utils/AuthContext';
import { useHistory } from "react-router-dom";
import { db } from "../utils/firebase";
import { collection, query, where, getDocs, Timestamp, orderBy, getDoc } from "firebase/firestore";
import HistoryData from '../components/HistoryData';
import SearchHistory from '../components/SearchHistorique';

const HistoryUser = () => {
  const [userData, setUserData] = useState([]);
  const [code, setCode] = useState("");
  const [dataFromCode, setDataFromCode] = useState([]);


const {currentUser} = useAuthValue();
const history = useHistory();

if (currentUser === null) {
  //redirect to home to
  history.push("/")
}


const handleClick = (e) => {
  e.preventDefault();
  getDataFromCode();
  console.log("code: " + code);
}

const a = query(collection(db, "Photos"), where("code", "==", code))
const getDataFromCode = (code) =>{
  if (code === "" && code.length !==6 ) {
    return  
  } else {
    getDocs(a).then((querySnapshot) => {
      const datasFromCode = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
      setDataFromCode([ ...datasFromCode, ...dataFromCode]);
    }).catch(reason => {
      console.log("Error : " + reason);
      return false;
    });
  }  
}
useEffect(() => {
  const q = query(collection(db, "Photos"), where("userID", "==", currentUser.uid))
  const getData =  () => {
    getDocs(q).then((querySnapshot) => {
      const userhistory = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
      setUserData(userhistory);
    })
  }
  getData();
}, [])



  return (
    <div className="HistoryUser">
      <div className="title">
        <h2>Historique</h2>
      </div>
      <div className="searchByCode">
        <form >
          <input
          placeholder="Entrer un code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          />
        </form>
          <button onClick={handleClick}>Rechercher</button>
      </div>
      <ul className="list">
        <div className="codeData">
        {dataFromCode.map((data, index) => (
          <HistoryData key={index} history={data}/>
        ))}
        </div>
        {userData.sort((a,b) => b.date - a.date).map((history, index) => (
          <HistoryData key={index} history={history}/>
        ))}
      </ul>
    </div>
  );
};

export default HistoryUser;
