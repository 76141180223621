import React, { useState } from 'react';
import ImageHistory from './ImageHistory';

const HistoryData = ({history}) => {
const [open, setOpen] = useState(false);


const handleClick = () =>{
  if (open === true) {
    setOpen(false);
  } else {
    setOpen(true);

  }
}

  
  return (
    <div className="HistoryData">
      <div className="body">
      <p onClick={handleClick}>{history.code} - {new Date(history.date.seconds * 1000).toLocaleDateString("fr-FR")}</p>
      </div>
      {open ? (
        
        <ImageHistory history={history}/>
      ) : (
        null
      )}
      <hr></hr>
    </div>
  );
};

export default HistoryData;
