import React, { useState } from 'react';

const UserWaiting = ({doctors}) => {
  const [userWaiting,  setUserWaiting] = useState([]);

  console.log(doctors.users)



  return (
    <div className="UserWaiting">
      {doctors.users.map((user, index) => (
        <div className="wainting" key={index}>
          {!user.isAccepted? (
            <p>{user.firstName}</p>

          ): (
            null
          )}
        </div>
      ))}
    </div>
  );
};

export default UserWaiting;
