import React, { useState, useEffect } from "react";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../utils/firebase";

const ImageHistory = ({ history }) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const getPhotos = async () => {
      if (history.photos.toString().indexOf("data:image/jpeg;base64,") > -1) {
        setPhotos(history.photos);
        console.log(photos);
      } else {
        const storage = getStorage();
        history.photos.map(fileName => {
            getDownloadURL(ref(storage, `${fileName}`))
            .then(url => {
              setPhotos(prevState => [...prevState, url]);
            });
        });
      }
    };
    getPhotos();
  }, [history.photos]);

  return (
    <div className="ImageHistory">
      
      {photos.length == 0 ?  <p>Chargement...</p>:  photos.map((photo, index) => (
        <div className="div" key={index}>
          <img src={photo} alt="" />
        </div>
      ))}
    </div>
  );
};
export default ImageHistory;
