import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Home from './pages/Home';
import HistoryUser from './pages/HistoryUser';
import '../src/styles/index.scss';
import Nav from './components/Nav';
import {useState, useEffect} from 'react';
import {onAuthStateChanged} from 'firebase/auth';
import { AuthProvider } from './utils/AuthContext';
import { auth } from './utils/firebase';
import Hospital from './pages/Hospital';

function App() {
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
     })
  }, [])
  
  return (
    <Router>
      <AuthProvider value={{currentUser}}>
    <Nav />
    <Switch>
      <Route  exact path="/" component={Home} />
      <Route  path="/history" component={HistoryUser} />
      <Route  path="/hospital" component={Hospital} />

    </Switch>
    </AuthProvider>
    </Router>
  
  );
}


export default App;
