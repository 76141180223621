import React from 'react';
import logo from  "../assets/images/logoSTR.png";

const Nav = () => {
  return (

    <div className="nav">
      <img src={logo} alt=""  width="200"
        height="40" className="logo"/>
    </div>
  );
};

export default Nav;
