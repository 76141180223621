import React from 'react';
import fb from '../assets/images/facebook.png'; 
import twitter from '../assets/images/twitter.png'; 
import linkedin from '../assets/images/lkd.png'; 

const Footer = () => {


  return (
    <div className="Footer">
      <div className="social-medias">
      <a href="https://www.facebook.com/econo.fr/"><img className="facebook" src={fb}  alt="facebook"/></a>
      <a href="https://twitter.com/econoFR"><img className="twitter" src={twitter}  alt="twitter"/></a>
      <a href="https://lnkd.in/giQKv8x/"><img className="linkedin" src={linkedin}  alt="linkedin"/></a>
      </div>
    </div>
  );
};

export default Footer;
