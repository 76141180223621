import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBOkZ-SPJcH0DE6PWIq99TZG1qGTzJ28fM",
  authDomain: "e-cono.firebaseapp.com",
  databaseURL: "https://e-cono.firebaseio.com",
  projectId: "e-cono",
  storageBucket: "e-cono.appspot.com",
  messagingSenderId: "390106869135",
  appId: "1:390106869135:web:77bd8bf445c850bd652ffa",
  measurementId: "G-KJ62FSL40V",
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { auth };
export const db = getFirestore(app);
